import React, {useEffect} from 'react'
import {useLiveQuery} from 'dexie-react-hooks'
import {db} from '../db'
import {numberWithCommas} from '../utlis/numbers'
import useAxios from '../hooks/useAxios'

const PointDetail = () => {
  const userData = useLiveQuery(() => db.user.get(1))
  const [pointTransactions, setPointTransactions] = React.useState<Array<{
    points: number,
    expiration_date: string,
    detail: string
  }>>([])
  const axios = useAxios()

  useEffect(() => {
    axios.get('/api/point_transactions/')
      .then((res) => {
        setPointTransactions(res.data)
      })
  }, [])


  return (
    <main className="sm:mt-4 md:mt-10">
      <div className="container mx-auto sm:px-4">
        <section className="text-center text-2xl font-bold text-primary-brown md:text-3xl">
          <h1>トモタクポイント</h1>
        </section>
        <div className="m-auto w-full lg:w-1/2 max-w-[720px] p-2 mt-4">
          <div className="flex flex-col items-end bg-white rounded-sm p-4">
            <div className="flex justify-between items-center border-b-2 px-2 md:px-4 w-full">
              <span className="text-lg font-bold">残高</span>
              <span
                className="text-lg font-bold text-primary-dark">{numberWithCommas(userData?.user?.point || 0) || 0}pt</span>
            </div>
            <div className="w-4/5 md:w-1/2">
              <div className="flex gap-4 justify-between px-2 md:px-4 items-center">
                <span className="font-bold text-sm">予約中</span>
                <span
                  className="text-lg font-bold text-primary-dark">△{numberWithCommas(userData?.user?.reservedPoint || 0) || 0}pt</span>
              </div>
              <div className="flex gap-4 justify-between px-2 md:px-4 items-center">
                <span className="font-bold text-sm">利用可能</span>
                <span
                  className="text-lg font-bold text-primary-dark">{numberWithCommas((userData?.user?.point || 0) - (userData?.user?.reservedPoint || 0)) || 0}pt</span>
              </div>
            </div>
          </div>
          <p className="text-xs text-gray-500">※「予約中」とは、現在、申込中のファンドにポイントが割り当てられている状態を指します。申込手続きが完了すると、ポイントが差し引かれます。申込が完了しない場合、ポイントはお客様のアカウントに戻ります。</p>
          <div>
            <h2 className="mt-4 text-primary-text font-bold">有効期限</h2>
            <div className="flex flex-col gap-2 mt-2">
              {
                pointTransactions.length > 0 ?
                  pointTransactions.map((transaction, index) => (
                    <div key={index}
                         className="flex justify-between items-center bg-white rounded-sm p-2">
                      <span className="text-sm font-bold">{transaction.expiration_date}</span>
                      <div className="text-sm font-bold text-primary-dark text-right">
                        <span>{numberWithCommas(transaction.points)}pt</span>
                        <div className="text-xs text-gray-500">{transaction.detail}</div>
                      </div>
                    </div>
                  ))
                  :
                  <div className="flex justify-center items-center h-96">
                    <p className="text-2xl font-bold text-primary-dark">ポイントがありません</p>
                  </div>
              }
            </div>
          </div>
        </div>
        {

        }
      </div>
    </main>
  )
}

export default PointDetail