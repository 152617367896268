import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../../components/ui/Breadcrumb';
import {InputField} from '../../../components/ui/forms/Input';
import {RadioButton} from '../../../components/ui/forms/RadioInput';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {FundDataHeaderTable} from '../../../components/layouts/FundDataHeaderTable';
import FundPrivacyConformation from '../../../components/ui/FundPrivacyConformation';
import {useLiveQuery} from 'dexie-react-hooks';
import {db} from '../../../db';
import {convertToNumber, numberWithCommas} from '../../../utlis/numbers';
import {useFund} from '../../../hooks/useFund';
import {AgreementType, defaultAgreement} from '../../../store/funds';
import UserService from '../../../infrastructure/api/user';
import useAxios from '../../../hooks/useAxios';
import {ErrorList, ErrorsType} from '../../../components/ui/forms/ErrorList';
import ApplicationConformation from './ApplicationConformation';

const breadcrumbItems = [
  {url: '/', name: ''},
];

interface Option {
  value: boolean;
  label: string;
  checked?: boolean;
}

const UsePoint: Option[] = [
  {value: true, label: 'はい'},
  {value: false, label: 'いいえ'},
];

const WaitingForCancelApplication: React.FC = () => {
  const {funds} = useFund();
  const {slug} = useParams();
  const navigate = useNavigate();
  const fund = funds.find((val) => val.slug === slug);
  const [agreement, setAgreement] = useState<AgreementType>(defaultAgreement);
  const [disabled, setDisabled] = useState(true);
  const [usePoint, setUsePoint] = useState(false);
  const [useAll, setUseAll] = useState(false);
  const [usedPoint, setUsedPoint] = useState(0);
  const [units, setUnits] = useState<number>();
  const [step, setStep] = useState({confirm: false, apply: false});
  const user = useLiveQuery(() => db.user.get(1), []);
  const userService = new UserService(useAxios());
  const [errors, setErrors] = useState<ErrorsType>();

  useEffect(() => {
    if (agreement.first && agreement.second && agreement.third) {
      setDisabled(false);
    }
  }, [agreement.first, agreement.second, agreement.third]);

  useEffect(() => {
    if (usePoint && useAll) {
      const kuchiAmount = (units || 0) * (fund?.kuchi || 0);
      const twoPercent = kuchiAmount * 0.2;
      const applyPoint = ((user?.user?.point || 0) - (user?.user?.reservedPoint || 0)) > twoPercent ? twoPercent : (user?.user?.point || 0) - (user?.user?.reservedPoint || 0);
      setUsedPoint(applyPoint);
    } else if (!usePoint) {
      setUsedPoint(0);
    }
  }, [useAll, units, usePoint]);

  const handleConfirm = () => {
    if (fund && units) {
      const data = {
        'fund': fund.slug,
        'units': units,
        'point': usedPoint
      };
      userService.validateWaitingForCancel(data)
        .then(() => {
          setErrors({});
          setStep((prev) => ({...prev, confirm: true}));
        })
        .catch((err) => {
          setErrors(err.errors || err);
        })
    }
  };

  const handleApply = () => {
    if (fund && units) {
      const data = {
        'fund': fund.slug,
        'units': units,
        'point': usedPoint
      };
      userService.applyWaitingForCancel(data)
        .then(() => {
          setStep((prev) => ({...prev, apply: true}));
        })
        .catch((err) => {
          setErrors(err.errors || err);
          setStep({confirm: false, apply: false});
          alert('大変申し訳ございません。募集口数状況が変動いたしましたため、受付出来ませんでした。再度該当ファンドの詳細ページにて、ご確認下さい。');
        })
    }
  };

  const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    e.currentTarget.blur();
  }

  if (user && user.isAuthenticated && !user.investorRegistrationCompleted) {
    navigate(`/investor-registration/step${user.registrationStep}`);
  }
  if (!fund || !user) return <></>;

  if (fund.current_status !== 'キャンセル待ち受付中' || fund.current_recruitment?.applied) {
    navigate('/funds')
  }
  
  return (
    <React.Fragment>
      {
        !step.confirm ?
          <main className='sm:mt-4 md:mt-10'>
            <div className='container mx-auto sm:px-4'>
              <section className='py-1 bg-secondary-main'>
                <h2
                  className='bg-white py-5 text-center text-2xl font-bold text-secondary-main sm:py-8 md:text-3xl'>お申込をする</h2>
              </section>
              <div
                className='mt-5 grid grid-cols-1 items-center justify-center gap-5 sm:mt-14 md:grid-cols-2 lg:gap-10'>
                <figure
                  className='relative h-80 overflow-hidden px-2 max-[500px]:h-full'>
                  <img className='h-full w-full rounded-2xl object-cover' src={fund.thumbnail} alt=''/>
                </figure>
                <div>
                  <h3
                    className='px-1 pt-1 pb-3 text-lg font-semibold sm:px-0 sm:text-xl lg:text-2xl'>{fund.fund_name}</h3>
                </div>
              </div>
              <div className='mt-5 mb-10'>
                <table className='mt-3 w-full border-separate sm:border-spacing-1'>
                  <tbody>
                  <FundDataHeaderTable name='募集金額'>
                    <p>{numberWithCommas(fund.amount_raised)}万円</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='予定分配率'>
                    <p>{fund.percent}%</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='運用期間'>
                    <p>{fund.operation_start} ~ {fund.operation_end}</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='出資額(1口)'>
                    <p>{numberWithCommas(fund.kuchi)}円</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='募集口数'>
                    <p>{numberWithCommas(fund.number_kuchi)}口</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='分配回数'>
                    <p>{fund.total_dist}回</p>
                  </FundDataHeaderTable>
                  <FundDataHeaderTable name='募集期間'>
                    {
                      [...fund.recruiting].reverse().map((val, index) => (
                        <div key={index}>
                          {
                            fund.recruiting.length > 1 ?
                              <div>
                                {
                                  fund.current_recruitment?.id === val.id ?
                                    <span
                                      className='rounded-md px-4 font-semibold text-primary-dark bg-primary-light py-0.5'>{`${index + 1}次募集:${val.name_display}`}募集中</span>
                                    :
                                    <span className='font-semibold'>{`${index + 1}次募集:${val.name_display}`}</span>
                                }
                              </div> : <div>{val.name_display}</div>
                          }
                          <div>{val.start}〜{val.end}</div>
                          {
                            val.name === 'b' &&
                            <small className='opacity-50 text-xs'>※抽選結果は{val.result_date}</small>
                          }
                        </div>
                      ))
                    }
                  </FundDataHeaderTable>
                  </tbody>
                </table>
              </div>

              <div>
                <h3 className='relative my-5 pl-3 text-lg font-bold text-primary-brown sm:pl-5 sm:text-2xl'>
                  <span className='absolute left-0 h-8 w-2 bg-primary-brown'></span>
                  キャンセル待ち申込み内容入力
                </h3>
                <div className='grid gap-5 md:grid-cols-2 md:gap-10'>
                  <div className='px-2 py-5 bg-primary-light sm:px-5'>
                    <p className='text-base font-bold sm:text-lg'>ご希望する出資口数を入力ください</p>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
                      <div className='inline-flex items-center'>
                        <InputField
                          name=''
                          type='number'
                          width='w-full sm:w-32 mt-1'
                          label=''
                          onWheel={numberInputOnWheelPreventChange}
                          onChange={(e) => {
                            setUnits(convertToNumber(e.target.value) || 0);
                          }}
                          className='w-full sm:w-auto'
                          value={units || ''}
                          suggestion='※半角数字入力'
                          placeholder=''
                        />
                        <span className='mt-5 w-10 text-lg font-bold'>口</span>
                      </div>
                      <div
                        className='text-right text-lg font-bold text-primary-dark sm:text-xl xl:text-2xl'>出資金額:{numberWithCommas((units || 0) * fund.kuchi)}円
                      </div>
                    </div>
                    <ErrorList name='units' errors={errors}/>
                  </div>
                </div>
              </div>
              {
                (user?.user?.point || 0) - (user?.user?.reservedPoint || 0) > 0 &&
                <div
                  className='mt-5 grid items-center justify-items-center gap-5 bg-white px-3 py-8 sm:mt-10 md:grid-cols-2'>
                  <div>
                    <p className='mb-3 font-bold sm:text-lg'>トモタクポイントを利用しますか？</p>
                    <div className='flex justify-center gap-10 font-bold'>
                      {UsePoint.map((data, index) => (
                        <RadioButton
                          key={index}
                          label={data.label}
                          value='use_point'
                          name='tomotakPoint'
                          checked={data.value === usePoint}
                          onChange={() => {
                            setUsePoint(data.value);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  {
                    usePoint &&
                    <div>
                      <p
                        className='mb-3 font-bold sm:text-lg'>現在保有されているポイント:{numberWithCommas((user?.user?.point || 0) - (user?.user?.reservedPoint || 0))}pt</p>
                      <div className='flex justify-center gap-10 font-bold'>
                        <RadioButton
                          label='すべて使う'
                          value='use_point'
                          name='tomotaquPoint'
                          checked={useAll}
                          onChange={(e) => {
                            setUseAll(e.target.checked);
                          }}
                        />
                        <div className='flex items-center'>
                          <input
                            type='radio'
                            value='use_point'
                            name='tomotaquPoint'
                            checked={!useAll}
                            onChange={(e) => {
                              setUseAll(!e.target.checked);
                            }}
                            className='mr-2 h-5 w-5 accent-secondary-main peer'
                          />
                          <input
                            type='text'
                            className='pointer-events-none peer-checked:pointer-events-auto border border-gray-400 rounded py-1.5 px-2 disabled:opacity-50'
                            value={numberWithCommas(usedPoint)}
                            onChange={(e) => {
                              setUsedPoint(convertToNumber(e.target.value) || 0);
                            }}
                          />
                        </div>
                      </div>
                      <ErrorList name='point' errors={errors}/>
                    </div>
                  }
                </div>
              }
              <FundPrivacyConformation slug={fund.slug} setAgreement={setAgreement} agreement={agreement}/>
              <p
                className='mt-5 px-2 sm:mt-10 sm:text-center sm:text-lg sm:font-bold'>上記の内容でお申込をする場合は「確認」ボタンを押してください</p>
              <section
                className='mt-5 flex flex-col flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:gap-5 sm:px-0 lg:gap-10'>
                <button
                  disabled={disabled}
                  onClick={handleConfirm}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 font-bold text-white border-secondary-main py-2.5 bg-secondary-main sm:w-80 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                  確認
                </button>
                <Link to='/funds'
                      className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 font-bold border-secondary-main py-2.5 text-secondary-main sm:w-80 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                  ファンド情報ページへ戻る
                </Link>
              </section>
              <Breadcrumb
                items={breadcrumbItems}
                className='mt-10'
              />
            </div>
          </main> :
          <ApplicationConformation
            fundName={fund.fund_name}
            image={fund.thumbnail}
            kuchi={numberWithCommas(units)}
            investAmount={numberWithCommas((units || 0) * fund.kuchi)}
            cash={numberWithCommas((units || 0) * fund.kuchi - (usedPoint))}
            point={numberWithCommas(usedPoint)}
            handleApply={handleApply}
            completed={step.apply}
            handleBack={() => {
              setStep((prev) => ({...prev, confirm: false}))
            }}
          />
      }
    </React.Fragment>
  );
};

export default WaitingForCancelApplication;