import { ChoiceOptionType } from './index';
import React from 'react';
import {
  ACCOUNT_STATE_UPDATE,
  BANK_ACCOUNT_UPDATE,
  LOGIN_INFORMATION_UPDATE,
  MEMBER_INFORMATION_UPDATE,
  PERSONAL_INFORMATION_UPDATE,
} from './constants';
import {DocumentDetailType} from './funds';

export interface LoginInformationType {
  email: string;
}

export interface BankAccountType {
  account_holder: string;
  account_number: string;
  account_type: '普通' | '当座';
  bank_hiragana: string;
  bank_id: number;
  bank_name: string;
  branch_hiragana: string;
  branch_id: number;
  branch_name: string;
}

export interface PersonalInformationType {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  zipcode: string;
  address1: string;
  address2: string;
  address3: string;
  address4?: string | null;
  birth_date?: string;
}

export type AssetType = {
  asset_name: string;
  asset_amount: string;
};

export interface MemberInformationType {
  phone: string;
  sex: '男性' | '女性' | '';
  occupation: string;
  workplace: string;
  salary: number;
  investment_year: string;
  investment_funds: string;
}

export interface AccountType {
  bankAccount: BankAccountType;
  memberInformation: MemberInformationType;
  personalInformation: PersonalInformationType;
  loginInformation: LoginInformationType;
}

export interface InvestorRegistrationProps {
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  state: InvestorRegistrationType;
}

export interface InvestorRegistrationInputProps extends InvestorRegistrationProps {
  setState: React.Dispatch<React.SetStateAction<InvestorRegistrationType>>;
}

export const initialBankAccount: BankAccountType = {
  'account_holder': '',
  'account_number': '',
  'account_type': '普通',
  'bank_hiragana': '',
  'bank_id': 0,
  'bank_name': '',
  'branch_hiragana': '',
  'branch_id': 0,
  'branch_name': '',
};

export const initialPersonalInformation: PersonalInformationType = {
  'last_name': '',
  'first_name': '',
  'last_name_kana': '',
  'first_name_kana': '',
  'zipcode': '',
  'address1': '',
  'address2': '',
  'address3': '',
  'address4': '',
  'birth_date': '',
};

export const initialMemberInformation: MemberInformationType = {
  phone: '',
  sex: '',
  occupation: '',
  workplace: '',
  salary: 0,
  'investment_funds': '',
  'investment_year': '',
};

export type PasswordChangeType = {
  password: string;
  new_password1: string;
  new_password2: string
};

export type EmailChangeType = {
  email: string;
  otp: string;
};

const initialLoginInformation: LoginInformationType = {
  email: '',
};

export const defaultAccountState: AccountType = {
  memberInformation: initialMemberInformation,
  personalInformation: initialPersonalInformation,
  bankAccount: initialBankAccount,
  loginInformation: initialLoginInformation,
};

export const investmentFundsOptions: ChoiceOptionType[] = [
  { value: '余剰資金', label: '余剰資金' },
  { value: '退職金', label: '退職金' }
];

export const genderOptions: ChoiceOptionType[] = [
  { value: '男性', label: '男性' },
  { value: '女性', label: '女性' },
];

export const occupationOptions: ChoiceOptionType[] = [
  { value: '会社員', label: '会社員' },
  { value: '会社役員', label: '会社役員' },
  { value: '自営業', label: '自営業' },
  { value: '主婦', label: '主婦' },
  { value: '学生', label: '学生' },
  { value: 'その他', label: 'その他' },
];

export const yearsOfInvestOptions: ChoiceOptionType[] = [
  { value: '未経験', label: '未経験' },
  { value: '〜1年', label: '〜1年' },
  { value: '1〜3年', label: '1〜3年' },
  { value: '3〜5年', label: '3〜5年' },
  { value: '5〜10年', label: '5〜10年' },
  { value: '10年以上', label: '10年以上' },
];

export const purposeOfInvestOptions: ChoiceOptionType[] = [
  { value: '老後資金', label: '老後資金' },
  { value: '教育資金', label: '教育資金' },
  { value: '娯楽費', label: '娯楽費' },
  { value: '生活費', label: '生活費' },
  { value: 'その他', label: 'その他' },
];

export const investmentPolicyOptions: ChoiceOptionType[] = [
  { value: '短期', label: '短期' },
  { value: '長期', label: '長期' },
];

export const breadcrumbItems = [
  { url: '/', name: '' },
];

export const investorInfo = [
  { title: '住所' },
  { title: '電話番号' },
  { title: '性別' },
  { title: 'ご職業' },
  { title: '勤務先' },
  { title: '年収' },
  { title: '投資目的' },
  { title: '投資方針' },
  { title: '資産状況' },
  { title: '投資経験' },
  { title: '投資年数' },
  { title: '投資資金' },
];

export const experienceChoice: ChoiceOptionType[] = [
  { value: '不動産特定共同事業法商品', label: '不動産特定共同事業法商品' },
  { value: '株式', label: '株式' },
  { value: '国債・社債', label: '国債・社債' },
  { value: '保険', label: '保険' },
  { value: '外貨預金', label: '外貨預金' },
  { value: '金', label: '金' },
  { value: '不動産投資', label: '不動産投資' },
  { value: '不動産投資信託', label: '不動産投資信託' },
  { value: '証券投資信託', label: '証券投資信託' },
  { value: '経験なし', label: '経験なし' },
];

export interface InvestorRegistrationType extends PersonalInformationType, MemberInformationType, BankAccountType {
  investment_policy: string;
  investment_purpose: string;
  investment_experience: string[];
  asset_type: AssetType[];
}


export const initialInvestorRegistration: InvestorRegistrationType = {
  'last_name': '',
  'first_name': '',
  'last_name_kana': '',
  'first_name_kana': '',
  'investment_policy': '',
  'investment_purpose': '',
  'investment_year': '',
  'investment_funds': '',
  'investment_experience': [],
  'asset_type': [],
  zipcode: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  phone: '',
  sex: '',
  occupation: '',
  workplace: '',
  salary: 0,
  ...initialBankAccount,
};


export const assetTypeOptions: ChoiceOptionType[] = [
  { value: '現金・預金', label: '現金・預金' },
  { value: '投資信託', label: '投資信託' },
  { value: '株式・社債', label: '株式・社債' },
  { value: 'その他', label: 'その他' },
];

export type AccountActionType =
  | { type: typeof MEMBER_INFORMATION_UPDATE; payload: { memberInformation: MemberInformationType; }; }
  | { type: typeof PERSONAL_INFORMATION_UPDATE; payload: { personalInformation: PersonalInformationType; }; }
  | { type: typeof BANK_ACCOUNT_UPDATE; payload: { bankAccount: BankAccountType; }; }
  | { type: typeof LOGIN_INFORMATION_UPDATE; payload: { loginInformation: LoginInformationType; }; }
  | { type: typeof ACCOUNT_STATE_UPDATE; payload: AccountType };

export const accountReducer = (state: AccountType = defaultAccountState, action: AccountActionType): AccountType => {
  switch (action.type) {
    case MEMBER_INFORMATION_UPDATE:
      return {
        ...state,
        memberInformation: action.payload.memberInformation,
      };
    case PERSONAL_INFORMATION_UPDATE:
      return {
        ...state,
        personalInformation: action.payload.personalInformation,
      };
    case BANK_ACCOUNT_UPDATE:
      return {
        ...state,
        bankAccount: action.payload.bankAccount,
      };
    case LOGIN_INFORMATION_UPDATE:
      return {
        ...state,
        loginInformation: action.payload.loginInformation,
      };
    case ACCOUNT_STATE_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export type TypeOfHistory = 'TAX' | 'DIVIDEND' | 'FUND' | 'PAYMENT' | 'WITHDRAWAL' | 'REPAY' | 'RETURN' | 'DEPOSIT' | 'POINT' | 'キャッシュバック' | 'ALLOCATE_POINT'

export type UserHistoryType = {
  type: TypeOfHistory;
  type_display: string;
  amount: string;
  fund: string;
}

export type GroupedHistoryType = {
  date: string;
  data: UserHistoryType[];
}

export type GroupedHistoryPaginationType = {
  num_pages: number;
  data: GroupedHistoryType[];
}

export type InvestmentStatusFundType = {
  id: number;
  kuchi: number;
  amount_of_investment: number;
  thumbnail: string;
  fund_name: string;
}

export type BaseInvestmentDetailType = InvestmentStatusFundType & {
  duration: string;
  duration_type: string;
  operation_start: string;
  operation_end: string;
  percent: number;
  for_user: DocumentDetailType;
  users_contract_documents: DocumentDetailType;
  electronic_transaction: DocumentDetailType;
  anonymous_partnership_contracts: DocumentDetailType;
}

export type InvestmentPaginationType = {
  data: InvestmentStatusFundType[];
  lastPage: number;
}

export type InvestmentStatusType = {
  runningFunds: InvestmentStatusFundType[];
  finishedFunds: InvestmentStatusFundType[];
  beforeRunningFunds: InvestmentStatusFundType[];
  pendingApplications: PendingApplicationType[];
  finishedFundsCount: number;
  chartData: (string | number)[][]
}

export type InvestmentDetailDividendType = {
  receive_date: string;
  order: number;
  receive_money: number;
}

export type InvestmentDetailFundType = BaseInvestmentDetailType & {
  def_time: string;
  fund_type: 'T' | 'R';
  dividends: InvestmentDetailDividendType[];
  next_dividend: InvestmentDetailDividendType;
  users_property_management_report: DocumentDetailType[] | null;
  crowd_property_management_report: DocumentDetailType[] | null;
  paper_file: string | null;
}

export type UserGroupedDividendType = {
  year: string;
  month: string;
  total_receive: number;
}[];


export type MyNumberType = '0' | '1' | '2';

export type MyNumberImageType = {
  front: string | ArrayBuffer | null;
  back: string | ArrayBuffer | null;
  resident: string | ArrayBuffer | null;
}

export type MyNumberImageDataType = {
  image_data: string | ArrayBuffer | null;
  image_type: string;
}

export type MyNumberDataType = {
  status: string;
  mynumber_images: MyNumberImageDataType[];
}

export type PendingApplicationType = {
  id: number;
  user_finance: InvestmentStatusFundType;
}

export type VaListType = {
  vaId: string;
  vaBranchCode: string;
  vaBranchNameKana: string;
  vaAccountNumber: string;
}

export type VaDataType = {
  vaTypeCode: string;
  vaTypeName: string;
  expireDateTime: string;
  vaHolderNameKana: string;
  vaList: VaListType[];
}

export type PendingApplicationDetailType = PendingApplicationType & {
  user_finance: BaseInvestmentDetailType;
  units: number;
  created_time: string;
  transfer_until: string;
  reserved_point: number;
  transfer_deposit: number;
  va_id?: string;
  va_data?: VaDataType;
  total_transaction_amount: number;
  gmo_transaction_data?: object[];
  reserved_deposit: number;
  status: 'pending' | 'ready';
  investment_amount: number;
}