import React, { useEffect, useMemo, useState } from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import RestructuringConform from './Conform';
import {db, RestructuringFundData} from '../../db';
import FundService from '../../infrastructure/api/funds';
import useAxios from '../../hooks/useAxios';
import {useLiveQuery} from 'dexie-react-hooks';
import {numberWithCommas} from '../../utlis/numbers';
import {InvestmentCompleted} from '../fund/fundApplication/InvestmentCompleted';
import {convertToJapaneseDate} from '../../utlis/date';

const breadcrumbItems = [
  {url: '/', name: ''},
];

const RestructuringFund: React.FC = () => {
  const {id} = useParams();
  const [next, setNext] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [state, setState] = useState<RestructuringFundData[]>([]);
  const [fundName, setFundName] = useState('')
  const navigate = useNavigate();
  const userData = useLiveQuery(() => db.user.get(1));
  const user = userData?.user;
  const restructuringFund = useMemo(() => {
    return user?.restructuringFunds?.find(fund => fund.id === Number(id))
  }, [user, id]);
  const fundService = new FundService(useAxios());

  useEffect(() => {
    if (restructuringFund?.data) {
      const cpData = getDeclineData(restructuringFund.data);
      setState(cpData);
    }
  }, [restructuringFund?.data]);

  const handleNext = () => {
    setNext(true);
  }
  const closeModal = () => {
    db.table('userInterface').update(1, {hideRestructuring: true});
    navigate('/');
  };

  const getDeclineData = (data: RestructuringFundData[]) => {
    const cpData = [...data];
    for (let i = 0; i < cpData.length; i++) {
      cpData[i].status = 'declined';
    }
    return cpData;
  }
  const handleDecline = () => {
    if (restructuringFund?.data) {
      const cpData = getDeclineData(state);
      fundService.putRestructuringFund(restructuringFund.id, cpData)
        .then(() => {
          db.table('user').update(1, {'user.restructuringFund': null});
          closeModal();
        })
    }
  }

  const handleSubmit = () => {
    if (restructuringFund?.data) {
      fundService.putRestructuringFund(restructuringFund.id, state)
        .then(() => {
          if (restructuringFund?.fund) {
            setFundName(restructuringFund.fund.restructuring.fund_name)
          }
          const rfs = user?.restructuringFunds?.filter(fund => fund.id !== Number(id));
          db.table('user').update(1, {'user.restructuringFunds': rfs || []});
          db.table('userInterface').update(1, {hideRestructuring: true});
          setCompleted(true);
        })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const checked = e.target.checked;
    const cpData = [...state];
    cpData[i].status = checked ? 'approved' : 'declined';
    setState(cpData);
  }
  
  if (completed) return <InvestmentCompleted fundName={fundName} />
  
  if (!restructuringFund) return <></>;
  
  return (
    <>
      {
        !next ?
          <main className='sm:mt-4 md:mt-10'>
            <div className='container mx-auto px-2 sm:px-4'>
              <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
                <h1>更新お手続き</h1>
              </section>
              <section className='mt-12 bg-primary-light'>
                <div className='px-2 py-8 text-base sm:px-12 sm:text-lg'>
                  <p
                    className='text-left'>{restructuringFund.fund.restructuring.fund_name}となる後継ファンドと、ご契約いただいておりました{restructuringFund.fund.origin.fund_name}の違いは下記の表通りとなります。</p>
                </div>
              </section>
              <section className='mx-auto mt-12 items-center overflow-x-auto xl:px-[10%]'>
                <table className='border w-[850px] bg-white border-secondary-main lg:w-full'>
                  <thead>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <th className='border-r border-opacity-50 py-2 border-secondary-main'></th>
                    <th className='border-r border-opacity-50 py-2 border-secondary-main'>出資中ファンド</th>
                    <th className='px-2'>後継ファンド</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>ファンド名
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{restructuringFund.fund.origin.fund_name}
                    </td>
                    <td className='px-2'>{restructuringFund.fund.restructuring.fund_name}</td>
                  </tr>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>運用開始日
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{convertToJapaneseDate(restructuringFund.fund.origin.operation_start)}</td>
                    <td className='px-2'>{convertToJapaneseDate(restructuringFund.fund.restructuring.operation_start)}</td>
                  </tr>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>運用終了日
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{convertToJapaneseDate(restructuringFund.fund.origin.operation_end)}</td>
                    <td className='px-2'>{convertToJapaneseDate(restructuringFund.fund.restructuring.operation_end)}</td>
                  </tr>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>予定分配率(税引前)
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{restructuringFund.fund.origin.percent}％
                    </td>
                    <td className='px-2'>{restructuringFund.fund.restructuring.percent}％</td>
                  </tr>
                  <tr className='border-b border-opacity-50 border-secondary-main'>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>優先出資額
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{numberWithCommas(restructuringFund.fund.origin.total_investment)}万円
                    </td>
                    <td
                      className='px-2'>{numberWithCommas(restructuringFund.fund.restructuring.total_investment)}万円
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='border-r border-opacity-50 px-2 py-1 font-bold opacity-50 border-secondary-main'>劣後出資額
                    </td>
                    <td
                      className='border-r border-opacity-50 px-2 border-secondary-main'>{numberWithCommas(restructuringFund.fund.origin.subordinated_investment)}万円
                    </td>
                    <td
                      className='px-2'>{numberWithCommas(restructuringFund.fund.restructuring.subordinated_investment)}万円
                    </td>
                  </tr>
                  </tbody>
                </table>
              </section>
              <section className='mt-12 bg-primary-light'>
                <div className='px-2 py-8 text-base sm:px-12 sm:text-lg'>
                  <p
                    className='text-left'>
                    また、後継ファンドの契約内容及び重要事項は、記載事項以外出資中の{restructuringFund.fund.origin.fund_name}の原契約に従うものとします。</p>
                  <p>
                    出資持分である出資額をもって、再組成した後継ファンドへそのまま移管する移管手続きに同意される場合は「出資申込内容確認へ」ボタンを押してください。
                  </p>
                </div>
              </section>
              <div
                className='mt-8 mb-5 flex flex-wrap items-center justify-center gap-3 px-2 sm:mt-16 sm:mb-10 sm:gap-5 lg:gap-10'>
                <button
                  onClick={closeModal}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-sm font-bold text-secondary-main border-secondary-main py-2.5 sm:text-primary-brown sm:border-primary-brown sm:w-72 sm:py-4 sm:text-base md:text-xl lg:w-[48%] max-[400px]:w-full'>
                  保留してマイページTOPに戻る
                </button>
                <button
                  type='button'
                  onClick={handleNext}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white bg-secondary-main border-secondary-main py-2.5 sm:w-72 sm:py-4 sm:text-base md:text-xl lg:w-[48%] max-[400px]:w-full'>
                  出資申込内容確認へ
                </button>
              </div>
              <div className='mt-8 flex flex-wrap items-center justify-center'>
                <button
                  type='button'
                  onClick={handleDecline}
                  className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-grey py-2.5 bg-primary-grey sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                  更新せず再組成に出資しない
                </button>
              </div>
              <Breadcrumb items={breadcrumbItems}/>
            </div>
          </main>
          :
          <RestructuringConform
            data={restructuringFund}
            handleDecline={handleDecline}
            closeModal={closeModal}
            state={state}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
      }
    </>
  );
};

export default RestructuringFund;