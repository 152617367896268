import React from 'react';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { ComingSoon } from './ComingSoon';
import { Recruiting } from './Recruiting';
import { FundLayout } from './FundLayout';
import { useFund } from '../../hooks/useFund';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/funds', name: 'ファンド一覧' },
];

const FundList: React.FC = () => {
  const { funds, comingSoon, setState } = useFund();

  return (
    <main className='sm:mt-4 md:mt-10'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>ファンド一覧</h1>
        </section>
        {
          funds.filter((val) => val.current_status === '募集前' || val.current_status === '募集中' || val.current_status === 'キャンセル待ち受付中').map((value, index) =>
            <Recruiting key={index} state={value} setState={setState} funds={funds}/>
          )
        }
        {
          comingSoon && Object.keys(comingSoon).length > 0 &&
          <ComingSoon
            {...comingSoon}
          />
        }
        <section className='mt-14 px-1 sm:px-4'>
          <div
            className='mx-auto grid grid-cols-2 gap-x-5 gap-y-14 md:gap-x-10 lg:grid-cols-3 xl:w-[90%] max-[560px]:grid-cols-1'>
            {
              funds.map((value, index) =>
                <FundLayout key={index} {...value}/>,
              )
            }
          </div>
        </section>
        <div className='mt-20 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
          <a href='https://www.tomotaqu.com/fund_list/page/1/'
                className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-base font-bold text-primary-dark border-primary-dark py-2.5 sm:py-3 sm:text-lg md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            TOMOTAQUファンド一覧を見る
          </a>
        </div>
        <Breadcrumb
          items={breadcrumbItems}
          className='mt-10'
        />
      </div>
    </main>
  );
};

export default FundList;