import React, {useEffect, useRef, useState} from 'react';
import useAxios from '../hooks/useAxios';
import {FundListType, RecruitingTypeWSMessage} from '../store/funds';
import FundService from '../infrastructure/api/funds';
import {BACKEND_WS_URL} from '../store';

type FundContextType = [
  FundListType,
  React.Dispatch<React.SetStateAction<FundListType>>
]

export const FundContext = React.createContext<FundContextType>(null!);

interface FundProviderProps {
  children: React.ReactNode;
}

export const FundProvider: React.FC<FundProviderProps> = ({children}) => {
  const [state, setState] = useState<FundListType>({funds: [], comingSoon: null});
  const [receivedMessage, setReceivedMessage] = useState<RecruitingTypeWSMessage | null>(null);
  const fundService = new FundService(useAxios());
  const socketRef = useRef(false);

  useEffect(() => {
    fundService.getFundList()
      .then((data) => {
        setState(data);
      });
  }, []);

  useEffect(() => {
    const setupWebSocket = () => {
      const socket = new WebSocket(`${BACKEND_WS_URL}/ws/fund/`);

      socket.onopen = () => {
        socketRef.current = true;
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setReceivedMessage(data);
      };
      return socket;
    };
    let socket: WebSocket | undefined;
    if (!socketRef.current) {
      socket = setupWebSocket();
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (receivedMessage) {
      const cpFund = [...state.funds];
      state.funds.every((fund, i) => {
        const recruitIndex = fund.recruiting.findIndex((recruit) => recruit.id === receivedMessage.id);
        if (recruitIndex !== -1) {
          cpFund[i].recruiting[recruitIndex]['units_remainder'] = receivedMessage['units_remainder'];
          if (cpFund[i].current_recruitment !== null && cpFund[i].current_recruitment !== undefined) {
            cpFund[i]['current_recruitment']!['units_remainder'] = receivedMessage['units_remainder'];
          }
          if (cpFund[i].recruiting[recruitIndex].name === 'a' && receivedMessage['units_remainder'] <= 0) {
            if (!cpFund[i].next_recruitment && !cpFund[i].current_recruitment?.will_use_wfc){
              cpFund[i]['current_status'] = '運用決定｜完売';
            } else if (cpFund[i].current_recruitment?.will_use_wfc) {
              cpFund[i]['current_status'] = 'キャンセル待ち受付中';
            }
          }
          return false;
        }
        return true;
      });
      setState((prev) => ({...prev, funds: cpFund}));
    }
  }, [receivedMessage?.units_remainder]);

  return (
    <FundContext.Provider value={[state, setState]}>
      {children}
    </FundContext.Provider>
  );
};
