import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {NewsListType} from '../../store/news';
import NewsService from '../../infrastructure/api/news';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
    {url: '/', name: 'マイページトップ'},
    {url: '/news', name: 'お知らせ一覧'}
];


const NewsDetails: React.FC = () => {
    const [news, setNews] = useState<{ data: NewsListType, next: number, previous: number }>();
    const {id} = useParams();
    const newsService = new NewsService(useAxios());

    useEffect(() => {
        if (id) {
            newsService
                .getDetailNews(id)
                .then((data) => {
                    setNews(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id]);
    if (!news) return <></>
    return (
        <main className='sm:mt-4 md:mt-10'>
            <div className='container mx-auto px-2 sm:px-4'>
                <section className='pt-10'>
                    <div>
            <span
                className='border px-2 py-1 text-xs text-primary-brown bg-secondary-brown border-primary-brown sm:text-sm'>{news.data.category}</span>
                        <span className='pl-2 text-xs text-primary-brown sm:text-sm'>{news.data.post_date}</span>
                    </div>
                    <h1
                        className='mt-5 border-b-4 pb-2 text-lg font-bold border-primary-dark text-primary-brown sm:text-2xl lg:text-3xl'>{news.data.title}</h1>
                    <div className='mt-5 border-b-4 border-primary-dark'>
                        <div className='new-content ck-content'>
                            <p dangerouslySetInnerHTML={{__html: news.data.body || ''}}/>
                        </div>
                        <div className='mt-10 flex flex-wrap items-center justify-center gap-3 pb-5 sm:gap-5 lg:gap-10'>
                            <Link to='/news'
                                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-base font-bold text-white py-2.5 bg-primary-brown sm:py-3 sm:text-lg md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                                お知らせ一覧を見る
                            </Link>
                        </div>
                    </div>
                </section>
                <section className='mt-5 sm:mt-10'>
                    <div className='flex items-center justify-center gap-3 text-primary-brown'>
                        {
                            news.previous &&
                            <Link to={`/news/${news.previous}`}
                                  className='flex h-10 w-20 items-center justify-center rounded-full border bg-white font-bold border-primary-brown'>
                                前へ
                            </Link>
                        }
                        {
                            news.next &&
                            <Link to={`/news/${news.next}`}
                                  className='flex h-10 w-20 items-center justify-center rounded-full border bg-white font-bold border-primary-brown'>
                                次へ
                            </Link>
                        }
                    </div>
                </section>
                <Breadcrumb className='mt-10' items={[...breadcrumbItems, {url: '', name: news.data.title}]}/>
            </div>
        </main>
    );
};

export default NewsDetails;